import { Map, View , Overlay} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import iconSar from './logoSar.png';
import iconOhz from './logoOhz.png';
// import iconCux from './logoCux.png';
import iconJoh from './logoJoh.png';
import iconUnt from './logoUnt.png';
import iconWes from './logoWes.png';
import iconRhs from './logoRhs.png';
import { OSM } from 'ol/source';

var sar = new Feature({
  id: 1,
  geometry: new Point(fromLonLat([7.9830062, 53.126169])),
  text: 'S.A.R. Suchhundestaffel Nord/West e.V.',
  link: 'https://sar-suchhundestaffel.de',
});

sar.setStyle(
  new Style({
    image: new Icon({
      anchor: [1, 1],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconSar,
    }),
  })
);

var ohz = new Feature({
  id: 2,
  geometry: new Point(fromLonLat([8.9254737, 53.2198818])),
  text: 'Rettungshunde im Landkreis Osterholz e. V.',
  link: 'https://www.rettungshunde-ohz.de/',
});

ohz.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconOhz,
    }),
  })
);

/* remove cuxland
var cux = new Feature({
  id: 3,
  geometry: new Point(fromLonLat([8.6462369, 53.357917])),
  text: 'Rettungshundestaffel CUXLAND e.V.',
  link: 'https://www.rhs-cuxland.de/',
});

cux.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconCux,
    }),
  })
);
*/

var joh = new Feature({
  id: 4,
  geometry: new Point(fromLonLat([8.2145521, 53.1434501])),
  text: 'Johanniter Unfallhilfe',
  link: 'https://www.johanniter.de/juh/lv-ndsbr/rv-weser-ems/unsere-standorte-einrichtungen-in-weser-ems/einrichtungen-detailseite/ortsverband-oldenburg-833/?utm_source=other&utm_medium=redirect&utm_campaign=oldenburg&utm_content=lvndsbr',
});

joh.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconJoh,
    }),
  })
);

/*
var unt = new Feature({
  id: 5,
  geometry: new Point(fromLonLat([8.794403, 53.609362])),
  text: 'BRH RHS Unterweser e.V.',
  link: 'http://www.rhs-unterweser.de/',
});

unt.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconUnt,
    }),
  })
);
*/

var wes = new Feature({
  id: 6,
  geometry: new Point(fromLonLat([7.947288424052053, 53.34700337068448])),
  text: 'Rettungshundestaffel Weser - Ems e.V.',
  link: 'https://www.rettungshundestaffel.org/',
});

wes.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconWes,
    }),
  })
);

var rhs = new Feature({
  id: 7,
  geometry: new Point(fromLonLat([9.997411973880553, 53.44806541128854])),
  text: 'Rettungshundestaffel Hamburg e.V.',
  link: 'https://www.rhs-hamburg.org/',
});

rhs.setStyle(
  new Style({
    image: new Icon({
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      scale: 0.43,
      src: iconRhs,
    }),
  })
);

var map = new Map({
  view: new View({
    center: fromLonLat([8.624094318433892, 53.42872301789393]),
    zoom: 8.5,
  }),
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    new VectorLayer({
      source: new VectorSource({
        features: [ohz, joh, sar, wes, rhs],
      }),
    }),
  ],
  target: 'map',
});

var element = document.getElementById('popup');

var popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
  offset: [0, -50],
});
map.addOverlay(popup);

map.on('click', function (evt) {
  var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });
  if (feature) {
    $(element).popover('dispose');
    var coordinates = feature.getGeometry().getCoordinates();
    popup.setPosition(coordinates);
    $(element).popover({
      placement: 'top',
      html: true,
      content:
        '<b>' +
        feature.get('text') +
        '</b></br><a href="' +
        feature.get('link') +
        '">' +
        "Hier geht's zur Webseite</a>",
    });
    $(element).popover('show');
  } else {
    $(element).popover('dispose');
  }
});

map.on('pointermove', function (evt) {
  var hit = this.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
    return true;
  });
  if (hit) {
    this.getTargetElement().style.cursor = 'pointer';
  } else {
    this.getTargetElement().style.cursor = '';
  }
});

$('#plz').keypress(function (e) {
  if (e.which === 13) {
    openModal();
    return false;
  }
});

$('#button').click(function () {
  openModal();
});

function openModal() {
  var plz = $('#plz').val();

  $.get('/nearestRescueLocation/' + plz, function (data, status) {
    if (status === 'success') {
      $('#modal-text').html(
        'Die nächste Hundestaffel ist <b>' +
          data.rescue +
          '</b> aus ' +
          data.from +
          '.</br>Sie ist ' +
          data.distanceText +
          ' von ' +
          data.to +
          ' entfernt.</br></br>' +
          'Für eine Alarmierung kontaktieren Sie bitte die <b>' +
          data.controlCentre +
          '</b>.'
      );
    } else {
      $('#modal-text').html(
        '<b>Wir konnten Ihre Anfrage leider nicht bearbeiten. Bitte prüfen Sie Ihre Eingabe oder probieren Sie es später noch einmal.</b>'
      );
    }
    $('#modal').modal('show');
  }).fail(function () {
    $('#modal-text').html(
      '<b>Wir konnten Ihre Anfrage leider nicht bearbeiten. Bitte prüfen Sie Ihre Eingabe oder probieren Sie es später noch einmal.</b>'
    );
    $('#modal').modal('show');
  });
}
